import "./css/reset.css"
import "./css/colors.css"
import "./css/typography.css"
import "./css/utils.css"
import "./css/layout.css"
import "./css/grid.css"
import "./css/icons.css"
import "./css/buttons.css"
import "./css/forms.css"
import "./css/header.css"
import "./css/footer.css"
import "./css/details.css"
import "./css/plugins.css"
import "./css/pagination.css"
import "./css/pro.css"
import "./css/edu.css"
import "./css/events.css"
import "./css/downloads.css"
import "./css/streamingteaser.css"
import "./css/donationteaser.css"
import "./css/newsletterwidget.css"
import "./css/newsletterteaser.css"
import "./css/team.css"
import "./css/map.css"
import "./css/loading.css"

import "./css/magazine.css"
import "./css/cards.css"
import "./css/cookiecontrol.css"
import "./css/films.css"
import "./css/film_detail.css"
import "./css/hero.css"
import "./css/player.css"
import "./css/filter.css"
import "./css/association.css"

import "./css/admin.css"
import "./css/variables.css"

import { onReady } from "./js/utils/dom"
import { initScrolledNav, initToggleNav } from "./js/components/nav"
import { initProToggle } from "./js/components/pro-toggle"
import { initFilmFilters, initFilmListDetails } from "./js/components/filters"
import { initVimeoPlayer } from "./js/components/vimeo"
import { initNewsletterWidgetToggle } from "./js/components/newsletterwidget"
import { initMap } from "./js/components/map"
import { initDetails } from "./js/lib/details"

window.htmx = require("htmx.org")

onReady(initDetails)
onReady(initToggleNav)
onReady(initScrolledNav)
onReady(initProToggle)
onReady(initVimeoPlayer)
onReady(initFilmFilters)
onReady(initNewsletterWidgetToggle)
onReady(initMap)

window.htmx.on("htmx:load", (evt) => {
  if (evt.target.tagName === "BODY" || evt.target?.id === "pre-filter") {
    initFilmListDetails()
  }
})
