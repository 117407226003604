import { setDetailsHeights } from "/js/utils/details"

export const initDetails = () => {
  const detailsElements = document.querySelectorAll(".details")

  if (detailsElements) {
    setDetailsHeights(detailsElements)

    document.body.addEventListener("click", (e) => {
      const details = e.target.closest(".details")
      document.querySelectorAll(".details[open]").forEach((el) => {
        if (el !== details) {
          el.removeAttribute("open")
        }
      })
    })
  }
}
